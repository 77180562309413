.home-section5 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0 186px;
  width: 100%;
  background-color: #202124;

  .notice-news-container {
    display: flex;
    flex-direction: column;
    // width: 1024px;

    .notice-news-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 44px;
      width: 100%;

      &-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 48px;
        color: #ee7029;

        @media (min-width: 768px) {
          font-size: 32px;
        }
        @media (min-width: 992px) {
          font-size: 40px;
        }
        @media (min-width: 1280px) {
        }
      }

      &-more-btn {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: #707070;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .notice-card-wrapper {
      width: 100%;
      // display: grid;
      // grid-template-columns: repeat(1, 1fr);
      // grid-row-gap: 20px;
      // grid-column-gap: 10px;

      // @media (min-width: 768px) {
      //   grid-template-columns: repeat(2, 1fr);
      // }
      // @media (min-width: 992px) {
      // }
      // @media (min-width: 1280px) {
      //   grid-template-columns: repeat(3, 1fr);
      // }

      .notice-news-card {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 15px;
        width: 100%;
        border-radius: 20px;
        transition: 0.1s ease-in-out;
        cursor: pointer;

        @media (min-width: 768px) {
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
          padding: 20px;
          // width: 410px;
        }

        &:hover {
          background-color: #2f3034;
        }

        &-image {
          margin-bottom: 10px;
          width: 100%;
          height: 181px;
          border-radius: 16px;

          @media (min-width: 768px) {
            height: 212px;
          }
          @media (min-width: 992px) {
            height: 258px;
          }
          @media (min-width: 1280px) {
            height: 295px;
          }

          &-src {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            object-fit: cover;
          }
        }

        &-info {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          padding: 14px;
          width: 100%;
          max-height: 110px;
          min-height: 110px;
          height: 110px;
          border-radius: 16px;

          @media (min-width: 768px) {
            padding: 14px;
            max-height: 146px;
            min-height: 146px;
            height: 146px;
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1280px) {
          }

          &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #ddf3ff;

            @media (min-width: 768px) {
              font-size: 16px;
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
            }
          }

          &-content {
            &-title {
              font-size: 14px;
              font-weight: 600;
              // white-space: nowrap;
              // text-overflow: ellipsis;
              // overflow-x: hidden;
              color: #fff;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;

              @media (min-width: 768px) {
                line-height: 26px;
                font-size: 18px;
              }
              @media (min-width: 992px) {
              }
              @media (min-width: 1280px) {
              }
            }

            &-description {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              color: #ddf3ff;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
}
