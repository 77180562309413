@import "src/view/style/variable";

header {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  height: $mobile_header_height;
  transition-duration: 0.5s;
  z-index: 5;

  @media (min-width: 768px) {
    height: $tablet_header_height;
  }
  @media (min-width: 992px) {
    height: $header_height;
  }
}

#top-nav3 {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  width: 100%;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.7);
    z-index: 5;
  }

  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
  @media (min-width: 1280px) {
    max-width: 1280px;
  }

  .header-logo-box {
    display: flex;
    align-items: center;

    .top-logo {
      width: 80px;
      object-fit: contain;

      @media (min-width: 768px) {
        width: 90px;
      }
      @media (min-width: 992px) {
        // width: 140px;
      }
      @media (min-width: 1280px) {
        width: 100px;
      }
    }
  }

  .top-nav-button {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;

    @media (min-width: 768px) {
      padding: 10px;
      font-size: 14px;
    }
    @media (min-width: 992px) {
      font-size: 14px;
    }
    @media (min-width: 1280px) {
      font-size: 16px;
    }

    .paper-list {
      position: absolute;
      bottom: -98px;
      left: 0;
      padding-top: 10px;
      width: 100%;

      &-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-radius: 4px;
        background-color: #000;

        &-item {
          padding: 14px 22px;
          font-size: 14px;
          font-weight: 500;
          color: #fff;

          &:hover {
            color: #d94525;
          }
        }
      }
    }

    &:hover > .top-nav-button-label {
      color: #d94525;
    }

    &-label {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      transition: 0.15s ease-in-out;

      @media (min-width: 992px) {
        font-size: 14px;
      }
      @media (min-width: 1280px) {
        font-size: 16px;
      }

      &.selected {
        color: #d94525;
      }

      &:active {
        font-weight: 500;
      }

      &.highlight {
        padding-bottom: 5px;
        border-bottom: 1px solid #d94525;
      }
    }

    &.selected {
      color: #d94525;
    }

    &:active {
      font-weight: 500;
    }

    &:hover {
      color: #d94525;
    }

    &:hover > .join-img {
      fill: #d94525;
    }

    .join-img {
      margin-right: 8px;
      width: 18px;
      fill: #fff;
      object-fit: contain;
      transition: 0.15s ease-in-out;

      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
        margin-right: 6px;
        width: 14px;
      }
      @media (min-width: 1280px) {
        margin-right: 8px;
        width: 18px;
      }
    }
  }

  .wallet-img {
    height: 10px;
    object-fit: contain;
    margin-right: 4px;
  }

  .disconnect-wallet-img {
    height: 14px;
    object-fit: contain;
    margin-right: 2px;
  }

  .dropdown-navigate-img {
    height: 7px;
    object-fit: contain;
    margin-left: 11px;
  }

  .wallet-tab-wrapper {
    position: absolute;
    box-sizing: border-box;
    width: 170px;
    top: 47px;
    left: -15px;
    border: solid 1px #dc4616;
    border-radius: 12px;
    overflow: hidden;

    .wallet-tab {
      display: flex;
      align-items: center;
      padding: 15px 0 13px 10px;
      border-bottom: 1px solid #707070;
      background-color: #303133;
      font-size: 14px;
      font-weight: 500;
      color: #e5e5e5;
      white-space: pre-wrap;
      cursor: pointer;

      &:last-child {
        border: none;
      }

      .wallet-tab-icon {
        width: 22px;
        object-fit: contain;
        margin-right: 4px;

        &.additional {
          width: 10px;
          margin-left: 4px;
          margin-right: 0;
        }
      }
    }
  }

  .sns-wrapper {
    display: flex;

    .header-sns-img {
      padding: 8px;
      width: 21px;
      object-fit: contain;
      cursor: pointer;

      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
        padding: 6px;
        width: 18px;
      }
      @media (min-width: 1280px) {
        padding: 8px;
        width: 21px;
      }
    }
  }

  .mobile-btn {
    margin: 0;
    padding: 0;
    width: 22px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @media (min-width: 768px) {
      width: 26px;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1280px) {
    }

    .mobile-btn-img {
      width: 100%;
      object-fit: contain;
    }
  }

  .mobile-tab-box {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: -210px;
    padding: 0 15px;
    width: 210px;
    height: 100vh;
    background-color: #202124;
    z-index: 6;
    transition: 0.2s;

    @media (min-width: 768px) {
      right: -420px;
      width: 420px;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1280px) {
    }

    .mobile-tab-wrapper {
      .mobile-close-tab {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;

        @media (min-width: 768px) {
          height: 60px;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
        }
      }

      .mobile-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        text-align: center;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        text-decoration: none;
        cursor: pointer;

        &.selected {
          color: #d94525;
        }

        &:hover {
          color: #d94525;
        }

        .join-img {
          margin-right: 8px;
          width: 14px;
          fill: #fff;
          object-fit: contain;
          transition: 0.15s ease-in-out;

          @media (min-width: 768px) {
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1280px) {
          }
        }
      }
    }
  }
}
.moving-text{
  font-weight: bold;
  font-size: 20px;
}
