@import "src/view/style/mixins";
@import "src/view/style/variable";

body {
  height: calc(var(--vh, 1vh) * 100);
  color: $exchange-text-main-color;
  background-color: #202124;
  overflow-x: hidden;

  .trade-container {
    overflow: inherit;
  }

  @include media(767px) {
    overflow: inherit;

    .trade-container {
      height: 100%;
    }
  }

  /* media query Safari 오류 해결 */
  @include media(782px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

#root,
#app {
  height: 100%;
}

.page {
  width: 100%;
  height: fit-content;
  flex-direction: column;
  margin-top: $mobile_header_height;

  @media (min-width: 768px) {
    margin-top: $tablet_header_height;
  }
  @media (min-width: 992px) {
    margin-top: $header_height;
  }
}

.flex {
  display: flex;
  &-column {
    display: flex;
    flex-direction: column !important;
  }
  &-vertical-align {
    flex-direction: row;
    align-items: center;
  }
}

.justify-content {
  &-space-between {
    justify-content: space-between;
  }
  &-space-around {
    justify-content: space-around;
  }
  &-flex-end {
    justify-content: flex-end;
  }
  &-center {
    justify-content: center;
  }
}

.align-items {
  &-center {
    align-items: center;
  }
  &-flex-end {
    align-items: flex-end;
  }
}

.ReactModal__Body--open {
  width: calc(100% - var(--scroll-width));
  height: 100%;
  overflow: visible;

  @include media(767px) {
    width: 100%;
  }

  header {
    width: calc(100% - var(--scroll-width));

    @include media(767px) {
      width: 100%;
    }
  }
}

.modal-overlay {
  width: 100% !important;
}

#trade {
  box-sizing: border-box;
  z-index: 0;
  padding: 30px 0;
  height: 100%;

  @include media(767px) {
    padding: 0 0 42px;
  }

  section {
    background-color: $section-main-color;
  }

  .mobile-tab {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    background-color: $section-main-color;

    label {
      flex: 1;
      cursor: pointer;
      height: 42px;
      border-top: solid 1px $border-white-sub-color;
      border-right: solid 1px $border-white-sub-color;
      color: $exchange-text-main-color;
      text-align: center;
      line-height: 42px;

      input[type="radio"] {
        display: none;

        &:checked + span {
          color: $decrease-color;
          font-weight: 500;
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .chart-header {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    height: 48.5px;
    padding: 9.25px 22px;
    border-bottom: solid 1px $border-white-sub-color;

    .chart-header-star-button {
      display: flex;
      cursor: pointer;
      .chart-header-star {
        width: 16px;
        height: 16px;
      }
      &:disabled {
        cursor: default;
      }
    }

    .chart-header-indicator {
      margin: 0 16.65px;
      font-weight: 500;
      color: $text-sub-black-color;
    }

    .chart-header-asset-image {
      width: 18.8px;
      height: 18.8px;
      padding-right: 7px;
    }

    .chart-header-market {
      font-size: 16px;
      font-weight: 500;
      color: $exchange-text-main-color;
      line-height: 1.33;
      &.market {
        color: $text-sub-black-color;
      }
    }

    .select-pair-button {
      height: 30px;
      padding: 0 10px;
      background-color: $decrease-color;
      border-radius: 20px;
      color: $exchange-text-main-color;
      font-size: 14px;
      font-weight: 500;
    }

    #eq-wallet-connect {
      font-size: 14px;
      height: 30px;
      padding: 0 12px;
    }
  }

  .increase {
    color: $increase-color !important;
  }

  .decrease {
    color: $decrease-color !important;
  }

  .chart-summary {
    box-sizing: border-box;
    border-bottom: solid 1px $border-white-sub-color;
    display: flex;
    height: 61px;
    padding: 8.5px 24px 9.5px;

    .chart-summary-item {
      display: flex;
      align-items: center;
      width: 158px;

      @include media(767px) {
        display: none;
      }

      &.current {
        align-items: flex-start;
        flex-direction: column;
        width: 185px;

        @include media(767px) {
          display: block;
        }
      }

      .chart-summary-icon {
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-right: 14.9px;
      }

      .chart-summary-sub-title {
        font-size: 12px;
        color: $text-sub-black-color;
        letter-spacing: -0.29px;
        margin-top: 2px;
      }

      .chart-summary-text {
        font-size: 16px;
        color: $increase-color;
        letter-spacing: -0.38px;
      }
      .current-price {
        font-size: 20px;
        font-weight: 500;
      }

      .change-price {
        font-size: 14px;
        letter-spacing: -1.12px;
      }
    }
  }

  .mobile-content {
    display: flex;
    flex-direction: column;
    background-color: $section-main-color;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: $section-main-color; /* or add it to the track */

      &-thumb {
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.4);
      }
    }

    .mobile-scroll {
      background-color: $section-main-color;
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 300px;
    }
  }

  .ReactVirtualized__List {
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: $section-main-color; /* or add it to the track */
    }

    /* Add a thumb */
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.4);
    }
  }
}
