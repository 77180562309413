@import "src/view/style/variable";
@import "src/view/style/mixins";

.gradient-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 20px 0;
  padding: 20px 30px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  background-image: linear-gradient(to right, #c2272f, #f25f02);

  @include tablet {
    margin: 0 0 30px 0;
  }
  @include laptop {
  }
  @include desktop {
    margin: 0 0 40px 0;
  }

  &:last-child {
    margin: 0;
  }

  @include tablet {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    border-radius: 20px;
  }
  &-left {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    @include tablet {
      align-items: center;
      flex-direction: row;
      margin-bottom: 0;
    }
    &-title {
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ffffff;
      font-size: 20px;
      font-weight: 500;
      color: #e5e5e5;
      @include tablet {
        width: 200px;
        margin-right: 20px;
        margin-bottom: 0;
        padding-bottom: 0;
        border-right: 1px solid #ffffff;
        border-bottom: none;
        font-size: 20px;
      }
      @include laptop {
        width: 260px;
        margin-right: 40px;
        font-size: 24px;
      }
      @include desktop {
        width: 290px;
        font-size: 28px;
      }
    }
    &-period {
      font-size: 14px;
      font-weight: 500;
      color: #e5e5e5;
      @include tablet {
        font-size: 16px;
      }
      @include laptop {
        font-size: 18px;
      }
      @include desktop {
        font-size: 20px;
      }
    }
  }
  &-right {
    display: flex;
    align-items: center;
    &-text {
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 18px;
      color: #e5e5e5;
      @include tablet {
        width: 200px;
        font-size: 20px;
      }
      @include laptop {
        width: 300px;
        font-size: 24px;
      }
      @include desktop {
        font-size: 28px;
        width: 530px;
      }
    }
  }
}
