.default-input{
  width: 100%;
  height: 36px;
  padding: 13px 20px;
  border-radius: 4px;
  border: solid 2px #e7e9ef;
  background-color: #fff;
  font-size: 14px;
  box-sizing: border-box;
  &::placeholder{
    color: #b1b1c1;
  }
  &:focus{
    outline: none;
    border: solid 2px #2657dc;
  }
}

input[type='datetime-local'], input[type='date']{
  font-family: DINPro;
}