@import "src/view/style/mixins";
@import "src/view/style/variable";
#trades {
  box-sizing: border-box;
  //display: flex;
  //flex-direction: column;
  width: 100%;
  height: 100%;

  .trades-title {
    box-sizing: border-box;
    padding: 16px 20px;
    height: 50px;
    font-size: 14px;
    color: $exchange-text-main-color;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .trades-header {
    table, thead, tr {
      width: 100%;
    }

    th {
      height: 50px;
      padding: 0 20px;
      font-size: 12px;
      color: $text-sub-black-color;
      font-weight: normal;
      text-align: right;
      &:first-child {
        text-align: left;
      }
    }
  }
  .trades-wrapper {
    width: 100%;
    height: calc(100% - 100px);
    box-sizing: border-box;
    overflow-y: scroll;

    table {
      width: 100%;
      padding: 0 20px;
      border-collapse: collapse;

      tr {
        height: 43px;
        border-bottom: solid 1px $border-white-sub-color;
        font-size: 14px;
        letter-spacing: -0.34px;

        &:last-child {
          border-bottom: none;
        }
      }

      td {
        text-align: right;
        padding: 0 20px;
        &:first-child {
          text-align: left;
        }
      }
    }
  }
  .trades-wrapper::-webkit-scrollbar {
    width: 5px;
  }

  .trades-wrapper::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
  }
}
