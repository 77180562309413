@import "variable";

@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin media($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

// @include media(768px) { color: red; };

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// @include border-radius(5px);

@mixin font-face($name, $file, $weight: noraml, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    font-weight: $weight;
    font-style: $style;
    src: url("../assets/fonts/#{$file}.ttf") format("truetype");
  }
}

// @include border-box();
@mixin border-box() {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

// @include max-width(1320px);
@mixin max-width($width) {
  max-width: $width;
  width: 100%;
}

// @include font-face('설정할 폰트 이름', '폰트 파일 이름');
// @include font-face('설정할 폰트 이름', '폰트 파일 이름', bold);
