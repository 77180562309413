@import "src/view/style/variable";
@import "src/view/style/mixins";

#public-sale-result {
  width: 100%;
  padding: 0;
  position: relative;
  color: white;

  .public-sale-result {
    &-background {
      background-color: #202124;
    }

    &-content {
      padding-top: calc(50px + #{$mobile_header_height});
      padding-bottom: 200px;
      @include tablet {
        padding-top: calc(50px + #{$tablet_header_height});
      }
      @include laptop {
        padding-top: calc(80px + #{$header_height});
      }
      @include desktop {

      }
    }

    &-notice{
      margin-top: 50px;
      width: 100%;
      padding: 20px 30px;
      border-radius: 20px;
      background-color: #3e4046;
      box-sizing: border-box;
      &-label{
        font-size: 24px;
        font-weight: 500;
        color: #e5e5e5;
      }
      &-text{
        margin-top: 20px;
        font-size: 16px;
        color: #e5e5e5;
        white-space: break-spaces;
      }
    }

    &-back-button {
      width: 32px;
      object-fit: contain;
    }

    &-label {
      font-size: 24px;
      font-weight: 500;
      color: #e5e5e5;
    }

    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 24px;
      padding: 40px;
      border-radius: 16px;
      background-color: #303133;

      &-info {
        font-size: 14px;
        color: #a3a3a3;
      }
    }

    &-history{
      position: relative;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-row-gap: 50px;
      grid-column-gap: 50px;
      justify-content: center;
    }

    &-project-text {
      font-size: 30px;
      font-weight: 500;
      color: #e5e5e5;
    }

    &-info-text{
      font-size: 16px;
      color: #e5e5e5;
      margin: 20px 0 0 10px;
      word-break: break-all;
    }

    &-table{
      margin-top: 20px;
      overflow-x: auto;
      table{
        width: fit-content;
        border: none;
        border-radius: 12px;
        @include tablet{
          width: 100%
        } 
      }
      thead{
        background-color: #27292d;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        color: #c3ccdc;
        @include tablet{
          font-size: 18px;
        }
      }
      tbody{
        background-color: #2c2e33;
        font-size: 14px;
        text-align: center;
        color: #e5e5e5;
        @include tablet{
          font-size: 16px;
        } 
      }
      th, td{
        border: none;
        height: 50px;
        min-width: 40%;
        width: fit-content;
        padding: 0 20px;
        white-space: nowrap;
        @include tablet{
          width: 20%;
          // min-width: 40%;
          // width: fit-content;
        } 
      }
      th{
        font-weight: 300; 
      }
      tbody tr{
        // cursor: pointer;
        border-bottom: solid 1px #27292d;
        &:last-child{
          border: none;
        }
      }
    }
  }
  .tooltip-icon{
    margin-left: 6px;
    width: 11px;
    height: 11px;
  }
}

