@import "src/view/style/variable";
@import "src/view/style/mixins";

.modal-invalid-device{
  width: 100%;
  @include tablet{
    width: 500px;
  }

  .content-text{
    display: flex;

  }
}
