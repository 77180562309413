@import "src/view/style/mixins";
@import "src/view/style/variable";

#order-history {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    height: 50px;
    label {
      flex: 1;
      cursor: pointer;

      input[type="radio"]:checked + span {
        color: $increase-color;
        border-bottom: 2px solid $increase-color;
      }

      input[type="radio"] {
        display: none;
        color: $increase-color;
        padding-top: 2px;
      }

      span {
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-bottom: 0.6px solid $border-white-sub-color;
        font-weight: bold;
        color: $exchange-text-main-color;
        line-height: 50px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: center;
    height: calc(100% - 52px);

    .no-data {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      color: $text-main-gray-color;
    }
    .no-login {
      font-weight: 500;
    }
    .table-wrapper {
      overflow: hidden;
      width: 100%;
      height: 100%;
      flex: 1;

      .row-wrapper {
        box-sizing: border-box;
        border-bottom: 0.6px solid $border-white-sub-color;
        letter-spacing: -0.29px;
        padding: 10px 15px 9px 20px;
        .top-data-wrapper {
          display: flex;
          justify-content: space-between;
          height: 16px;

          .top-data {
            font-size: 13px;
            color: $decrease-color;
            &.buy {
              color: $increase-color;
            }
            &.coin {
              font-weight: 500;
              margin-right: 6px;
            }
            &.trade {
              margin-right: 10px;
            }
          }
          .trash {
            width: 12px;
            cursor: pointer;
            object-fit: contain;
          }
        }
        .bottom-data-wrapper {
          margin-top: 16px;

          .bottom-row-wrapper {
            display: flex;
            justify-content: space-between;
            .data-name {
              font-size: 12px;
              color: $exchange-text-main-color;
            }
            .bottom-data {
              font-size: 12px;
              color: $exchange-text-main-color;
              margin-bottom: 6px;
              &.gray {
                color: #9e9e9e;
                margin-left: 5px;
              }
            }
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
