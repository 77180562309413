@import "src/view/style/variable";

#public-sale-history{
  width: 100%;
  padding: 0;
  position: relative;

  .main-helper {
    height: calc(500px + #{$header_height});
    background-color: #000;

    @media (min-width: 768px) {
      height: calc(870px + #{$header_height});
    }
    @media (min-width: 992px) {
      height: calc(1030px + #{$header_height});
    }
    @media (min-width: 1280px) {
      height: calc(1030px + #{$header_height});
    }
  }
}
