.history-card{
  position: relative;
  max-width: 400px;
  min-width: 360px;
  height: 441px;
  border-radius: 15px;
  box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #202021;
  background-color: #303133;
  overflow: hidden;
  cursor: pointer;
  &-img{
    width: 100%;
    height: 235px;
    object-fit: cover;
    background-color: white;
  }
  &-title{
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    color: #fff;
  }
  &-amount{
    font-size: 22px;
    text-align: right;
    color: #fff;
  }
  &-time{
    font-size: 22px;
    text-align: right;
    color: #969a9f;
  }
  &-accepted{
    font-size: 16px;
    text-align: right;
    color: rgba(0, 191, 115, 1);
  }
}