
@import "src/view/style/variable";
@import "src/view/style/mixins";

@keyframes clippath {
  0%,
  100% {
    clip-path: inset(0 0 95% 0);
  }
  25% {
    clip-path: inset(0 95% 0 0);
  }
  50% {
    clip-path: inset(95% 0 0 0);
  }
  75% {
    clip-path: inset(0 0 0 95%);
  }
}

.default-button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
  background-color: #38a0ff;
  color: white;
  cursor: pointer;
  white-space: nowrap;

  &.white{
    background-color: white;
    border: solid 1px #38a0ff;
    color: #38a0ff;
  }

  &.white-red{
    background-color: white;
    border: solid 1px #c93636;
    color: #c93636;
  }

  &.red{
    background-color: #c93636;
    color: white;
  }

  &.cancel {
    background-color: #1f1c1c;
    border: solid 1px #707070;
  }
}
.header-button{
  width: fit-content;
  height: fit-content;
  padding: 8px 14px;
  border: solid 1px #dc4616;
  border-radius: 17px;
  background-image: linear-gradient(to right, #e14a13, #c23125 51%, #781c5a 100%);
  font-family: DINPro;
  font-size: 14px;
  font-weight: 500;
  color: #e5e5e5;
  transition-delay: 5s;
  &.connect{
    background-image: none;
    background-color: #303133;
    transition-delay: 0s;
  }
}

.gradient-button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 265px;
  height: 47px;
  padding: 14px 35px;
  // border: solid 1px #dc4616;
  border-radius: 12px;
  background-image: linear-gradient(114deg, #e14a13 3%, #c23125 51%, #781c5a 97%);
  font-family: DINPro;
  font-size: 14px;
  font-weight: 500;
  color: #e5e5e5;
  transition-delay: 5s;
  box-sizing: border-box;
  &:hover{
    background-image: linear-gradient(108deg, #f15a23 3%, #d24135 51%, #882c6a 97%);
  }
  &.disabled, &:disabled{
    border: solid 1px #dc4616;
    padding: 13px 34px;
    background-image: none;
    background-color: #3d3f43;
  }
  &.bold{
    font-size: 18px;
    font-weight: 500;
    padding: 12px 18px;    
  }
}

.common-button{
  width: 288px;
  height: 47px;
  border-radius: 12px;
  border: solid 1px #707070;
  font-family: DINPro;
  font-size: 18px;
  font-weight: 500;
  color: #e5e5e5;
  box-sizing: border-box;
  @include tablet{
    width: 265px;
  }
  
  

  &:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }
  &.border-red{
    border: solid 1px #dc4616;
  }
  &.bold{
    font-size: 18px;
    font-weight: 500;
    // padding: 12px 18px;    
  }
}

.join-button{
  width: 288px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
  border: solid 1px #EC5000;
  border-radius: 12px;
  font-family: DINPro;
  font-size: 18px;
  font-weight: 500;
  color: #e5e5e5;
  cursor: pointer;
  box-sizing: border-box;
  @include tablet{
    width: 265px;
  }
  &:hover{
    background-color: rgba(255, 255, 255, 0.1);
  }
  &.bold{
    font-size: 18px;
    font-weight: 500;
  }
}

.button-animation {
  position: relative;
  
  &::before {
    content: "";
    position: absolute;
    width: 288px;
    height: 47px;
    top: -1px;
    left: -1px;
    right: 0;
    bottom: 0;
    border: 1px solid #ffa665;
    border-radius: 12px;
    animation: clippath 3s infinite linear;
    box-sizing: border-box;

    @media (min-width: 768px) {
    width: 265px;

    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1280px) {
    }
  }
}

.sale-gradient-button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 288px;
height: 47px;
  padding: 14px 35px;
  // border: solid 1px #dc4616;
  border-radius: 12px;
  background-image: linear-gradient(114deg, #e14a13 3%, #c23125 51%, #781c5a 97%);
  font-family: DINPro;
  font-size: 14px;
  font-weight: 500;
  color: #e5e5e5;
  box-sizing: border-box;
  @include tablet{
    width: 265px;
  }

  &:hover{
    background-image: linear-gradient(108deg, #f15a23 3%, #d24135 51%, #882c6a 97%);
  }
  &.disabled, &:disabled{
    border: solid 1px #dc4616;
    padding: 13px 34px;
    background-image: none;
    background-color: #3d3f43;
  }
  &.bold{
    font-size: 18px;
    font-weight: 500;
    padding: 12px 18px;    
  }
}