@import "src/view/style/mixins";
@import "src/view/style/variable";

.signature-modal {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  max-width: 500px;
  max-height: min(80vh, 580);

  .modal-header {
    min-height: 60px;
  }

  .modal-content {
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      &-thumb {
        background-color: $sub-background-color;
        border-radius: 3px;
      }
    }

    .modal-content-scroll-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .notice {
    text-align: center;
    font-size: 12px;

    p {
      font-family: inherit;
      color: #757575;
      line-height: 1.3;
      margin-bottom: 10px;
      white-space: pre-wrap;
    }

    strong {
      font-weight: normal;
      color: $decrease-color;
    }
  }


  input, &-info-box {
    background-color: $sub-background-color;
    border-radius: 6px;
    border: solid 1px #707070;
    color: $exchange-text-main-color;
  }

  &-info-box{
    padding: 12px 14px;
    font-size: 14px;
    letter-spacing: -0.28px;
    line-height: 1.43;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: pre-wrap;
  }

  input {
    height: 40px;
    padding: 0 14px;
    &:focus {
      border: solid 1px $decrease-color;
    }
  }

  .cancel-wrapper {
    width: 67px;
    height: 32px;
  }
  .confirm-wrapper {
    margin-left: 7px;
    width: 79px;
    height: 32px;

    button:disabled {
      background-color: #c6cbd8;
    }
  }
}
