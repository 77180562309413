footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #121213;

  .footer-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 100%;

    .top-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 15px;
      width: 100%;
      height: 190px;
      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 768px;
        height: 120px;
      }
      @media (min-width: 992px) {
        width: 992px;
        height: 130px;
      }
      @media (min-width: 1280px) {
        width: 1280px;
        height: 140px;
      }

      .footer-logo {
        margin-bottom: 30px;
        width: 140px;
        object-fit: contain;
        @media (min-width: 768px) {
          margin-bottom: 0;
          width: 120px;
        }
        @media (min-width: 992px) {
          width: 140px;
        }
        @media (min-width: 1280px) {
          width: 180px;
        }
      }

      .mailing-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        @media (min-width: 768px) {
          flex-direction: row;
          align-items: center;
          width: auto;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
        }

        .mailing-text {
          margin-bottom: 14px;
          font-size: 14px;
          font-weight: 600;
          color: #fff;

          @media (min-width: 768px) {
            margin-bottom: 0;
            font-size: 16px;
          }
          @media (min-width: 992px) {
            font-size: 18px;
          }
          @media (min-width: 1280px) {
            font-size: 21px;
          }
        }
        .mailing-input-box {
          position: relative;
          margin-left: 0;
          font-size: 21px;
          width: 100%;

          @media (min-width: 768px) {
            width: 320px;
            margin-left: 32px;
          }
          @media (min-width: 992px) {
            width: 400px;
            margin-left: 42px;
          }
          @media (min-width: 1280px) {
            width: 460px;
            margin-left: 63px;
          }

          .mailing-input {
            box-sizing: border-box;
            padding: 10px 105px 10px 15px;
            width: 100%;
            height: 36px;
            background-color: #000;
            border: none;
            border-radius: 30px;
            color: #929499;
            outline: none;

            @media (min-width: 768px) {
              padding: 10px 120px 10px 15px;
              width: 320px;
              height: 40px;
            }
            @media (min-width: 992px) {
              padding: 10px 150px 10px 15px;
              width: 400px;
              height: 50px;
            }
            @media (min-width: 1280px) {
              padding: 20px 170px 20px 20px;
              width: 460px;
              height: 60px;
            }

            &::placeholder {
              color: #929499;
            }
          }

          .mailing-btn {
            position: absolute;
            right: 0;
            box-sizing: border-box;
            width: 90px;
            height: 36px;
            border: none;
            border-radius: 30px;
            font-size: 12px;
            color: #fff;
            background: linear-gradient(to right, #c22630, #f36002);
            cursor: pointer;

            @media (min-width: 768px) {
              font-size: 14px;
              width: 110px;
              height: 40px;
            }
            @media (min-width: 992px) {
              font-size: 16px;
              width: 140px;
              height: 50px;
            }
            @media (min-width: 1280px) {
              font-size: 18px;
              width: 150px;
              height: 60px;
            }
          }
        }
      }
    }

    .middle-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 15px;
      width: 100%;

      @media (min-width: 768px) {
        flex-direction: row;
        padding: 30px 15px;
        width: 768px;
      }
      @media (min-width: 992px) {
        padding: 40px 15px;
        width: 992px;
      }
      @media (min-width: 1280px) {
        padding: 45px 15px;
        width: 1280px;
      }

      .middle-box {
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 0;
        }

        @media (min-width: 768px) {
          margin-bottom: 0;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
        }

        .middle-content-title {
          display: flex;
          margin-bottom: 20px;
          color: #fff;
          font-size: 20px;
          font-weight: bold;

          @media (min-width: 768px) {
            margin-bottom: 20px;
          }
          @media (min-width: 992px) {
            margin-bottom: 26px;
          }
          @media (min-width: 1280px) {
            margin-bottom: 32px;
          }
        }

        .middle-menu-list {
          margin: 0;
          padding: 0;
          list-style: none;
          color: #979ea5;
          font-size: 14px;
          font-weight: 500;

          @media (min-width: 768px) {
            font-size: 14px;
          }
          @media (min-width: 992px) {
            font-size: 16px;
          }
          @media (min-width: 1280px) {
            font-size: 16px;
          }

          .middle-menu-item {
            display: flex;
            align-items: center;
            padding: 8px 0;
            cursor: pointer;

            @media (min-width: 768px) {
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
            }

            .footer-menu {
              display: flex;
              align-items: center;
              text-decoration: none;
              color: #979ea5;
            }

            .img-box {
              margin-right: 8px;
              width: 14px;
              height: 14px;

              @media (min-width: 768px) {
                width: 14px;
                height: 14px;
              }
              @media (min-width: 992px) {
                width: 16px;
                height: 16px;
              }
              @media (min-width: 1280px) {
                width: 16px;
                height: 16px;
              }

              .join-img {
                width: 100%;
                object-fit: contain;
              }
            }
          }
        }

        .footer-info {
          color: #979ea5;
          font-size: 14px;
          line-height: 1.89;

          @media (min-width: 768px) {
            font-size: 14px;
          }
          @media (min-width: 992px) {
            font-size: 16px;
          }
          @media (min-width: 1280px) {
            font-size: 16px;
          }
        }

        .footer-sns-wrapper {
          .footer-sns {
            margin-right: 16px;
            width: 20px;
            object-fit: contain;
            cursor: pointer;

            @media (min-width: 768px) {
              width: 18px;
            }
            @media (min-width: 992px) {
              width: 20px;
            }
            @media (min-width: 1280px) {
              width: 22px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .bottom-box {
      .copyright {
        margin: 14px 0;
        font-size: 12px;
        color: #7c7d8d;

          @media (min-width: 768px) {
            font-size: 14px;
          }
          @media (min-width: 992px) {
            font-size: 15px;
          }
          @media (min-width: 1280px) {
            font-size: 15px;
          }
      }
    }
  }
}
