@import "src/view/style/variable";

.airdrop-section1-background {
  width: 100%;
  height: calc(650px + #{$header_height});

  @media (min-width: 768px) {
    height: calc(510px + #{$header_height});
  }

  @media (min-width: 992px) {
    height: calc(650px + #{$header_height});
  }

  @media (min-width: 1280px) {
    height: calc(810px + #{$header_height});
  }

}

.airdrop-container1 {
  position: absolute;
  top: 0;
  color: #fff;

  .airdrop-content1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;

    @media (min-width: 768px) {
      height: 560px;
    }

    @media (min-width: 992px) {
      height: 700px;
    }

    @media (min-width: 1280px) {
      height: 860px;
    }

    .airdrop-section1 {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;
      padding-top: 50px;
      width: 100%;

      @media (min-width: 768px) {
        flex-direction: row;
        padding-top: 60px;
      }

      @media (min-width: 992px) {
        padding-top: 80px;
      }

      @media (min-width: 1280px) {}

      .main-title-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 768px) {
          align-items: flex-start;
        }

        @media (min-width: 992px) {}

        @media (min-width: 1280px) {}

        .airdrop-main-title {
          margin-bottom: 12px;
          font-size: 44px;
          font-weight: bold;

          @media (min-width: 768px) {
            flex-direction: row;
            margin-bottom: 18px;
            font-size: 48px;
          }

          @media (min-width: 992px) {
            margin-bottom: 26px;
            font-size: 66px;
          }

          @media (min-width: 1280px) {
            margin-bottom: 48px;
            font-size: 88px;
          }
        }

        .airdrop-sub-title {
          margin-bottom: 20px;
          font-size: 15px;
          line-height: 1.33;
          text-align: center;
          white-space: break-spaces;

          @media (min-width: 768px) {
            text-align: left;
            width: 534px;
            font-size: 16px;
          }

          @media (min-width: 992px) {
            width: 666px;
            font-size: 20px;
          }

          @media (min-width: 1280px) {
            margin-bottom: 40px;
            width: 803px;
            font-size: 24px;
          }
        }

        .join-btn {
          box-sizing: border-box;
          width: 154px;
          height: 30px;
          border: 2px solid #d94525;
          border-radius: 5px;
          background-color: transparent;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          transition: .15s ease-in-out;
          cursor: pointer;

          @media (min-width: 768px) {
            width: 180px;
            height: 38px;
            border-radius: 10px;
            font-size: 16px;
          }

          @media (min-width: 992px) {
            width: 218px;
            height: 44px;
            border-radius: 10px;
            font-size: 18px;
          }

          @media (min-width: 1280px) {
            width: 240px;
            height: 50px;
            border-radius: 10px;
            font-size: 20px;
          }

          &:hover {
            background-color: #d94525;
          }
        }
      }

      .logo-img-box {
        margin-bottom: 48px;

        @media (min-width: 768px) {
          margin-bottom: 0;
        }

        @media (min-width: 992px) {}

        @media (min-width: 1280px) {}

        .logo-img {
          width: 130px;
          object-fit: contain;

          @media (min-width: 768px) {
            width: 180px;
          }

          @media (min-width: 992px) {
            width: 280px;
          }

          @media (min-width: 1280px) {
            width: 417px;
          }
        }
      }
    }
  }
}

.airdrop-container2 {
  padding: 40px 0;
  width: 100%;
  background-color: #202124;
  position: relative;
  color: #fff;

  @media (min-width: 768px) {
    padding: 60px 0;
  }

  @media (min-width: 992px) {
    padding: 80px 0;
  }

  @media (min-width: 1280px) {
    padding: 116px 0;
  }

  .airdrop-content2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.airdrop-container3 {
  padding: 56px 0;
  width: 100%;
  background-color: #202124;
  position: relative;
  color: #fff;

  @media (min-width: 768px) {
    padding: 142px 0 160px;
  }

  @media (min-width: 992px) {
    padding: 174px 0 380px;
  }

  @media (min-width: 1280px) {
    padding: 222px 0 510px;
  }

  .airdrop-content3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}


.btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 60px;
  }

  @media (min-width: 992px) {
    margin-top: 80px;
  }

  @media (min-width: 1280px) {
    margin-top: 100px;
  }

  .join-btn-big {
    box-sizing: border-box;
    width: 200px;
    height: 40px;
    border: 2px solid #d94525;
    border-radius: 5px;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    transition: .15s ease-in-out;
    cursor: pointer;

    @media (min-width: 768px) {
      width: 300px;
      height: 50px;
      border-radius: 10px;
      font-size: 16px;
    }

    @media (min-width: 992px) {
      width: 360px;
      height: 60px;
      border-radius: 10px;
      font-size: 18px;
    }

    @media (min-width: 1280px) {
      width: 400px;
      height: 70px;
      border-radius: 10px;
      font-size: 20px;
    }

    &:hover {
      background-color: #d94525;
    }
  }
}
