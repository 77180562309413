@import "src/view/style/mixins";
@import "src/view/style/variable";
#tickers {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  padding-top: 22px;

  .tickers-search-wrap {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 16px 0 22.8px;

    .tickers-search-star {
      width: 16px;
      height: 16px;
      cursor: pointer;
      padding-right: 10.2px;
    }

    .tickers-search-input {
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex: 1;
      height: 34px;
      padding: 0 10.4px 0 13px;
      background-color: $sub-background-color;
    }

    input {
      flex: 1;
      font-size: 12px;
      color: $text-sub-black-color;
      background-color: inherit;
      letter-spacing: -0.29px;
      border: none;
      outline: none;

      &::placeholder {
        color: $text-sub-black-color;
      }
    }

    .tickers-search-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    .close-btn {
      width: 34px;
      height: 34px;
      object-fit: contain;
    }
  }

  .tickers-tab {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 34px;

    label {
      flex: 1;
      cursor: pointer;
      &:last-child {
        span {
          border-left: none;
        }
      }
    }
    input[type="radio"] {
      display: none;
    }
    span {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      display: inline-block;
      border-bottom: 1px solid $border-white-sub-color;
      color: $text-sub-black-color;
      padding: 8px 0;
      text-align: center;
      font-size: 14px;
    }
    input[type="radio"]:checked + span {
      font-weight: 500;
      border-bottom: 2px solid $decrease-color;
      color: $decrease-color;
    }
  }

  .tickers-content-box {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }
  }

  .ag {
    &-right-aligned-cell {
      text-align: right;
    }
    &-react-container {
      height: 100%;
    }
    &-root-wrapper {
      border: none;
      background-color: $section-main-color;
      font-family:
        DINPro,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    }
    &-header {
      background-color: $section-main-color;
      border: none;

      &-row {
        font-weight: normal;
      }

      &-cell {
        box-sizing: border-box;
        padding: 0 10px;
      }

      &-cell-label {
        font-size: 12px;
        color: $text-sub-black-color;
      }
    }
    &-row {
      border: none;
      background-color: $section-main-color;
      &:hover {
        cursor: pointer;
        background-color: $sub-background-color;
      }
    }
    &-cell {
      line-height: 44px;
      &-value {
        box-sizing: border-box;
        padding: 0 10px;
      }
    }
  }

  .first {
    justify-content: flex-start;
    text-align: left;
  }

  .tickers-row {
    font-size: 12px;
    color: $text-sub-black-color;
  }

  .coin {
    font-weight: 500;
    color: $exchange-text-main-color;
    line-height: 1;
  }

  .volume-cell {
    text-align: right;
  }

  .default {
    color: $text-sub-black-color;
    line-height: 1;
  }

  .volume {
    color: $exchange-text-main-color;
  }

  .allow-word-break {
    white-space: normal;
    word-break: break-all;
    line-height: 1.3;
  }

  .asset {
    font-weight: 500;
    color: $exchange-text-main-color;
  }

  .up {
    color: $increase-color;
  }

  .down {
    color: $decrease-color;
  }
}
