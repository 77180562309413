.modal-header{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: solid 1px #707070;
  .modal-close-button{
    position: absolute;
    top: 15.5px;
    right: 16px;
    width: 26px;
  }
}