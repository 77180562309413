@import 'mixins';

@mixin font-face-OTF($name, $file, $weight: noraml, $style: normal) {
  @font-face {
    font-family: '#{$name}';
    font-weight: $weight;
    font-style: $style;
    src: url('../assets/fonts/#{$file}.otf') format('truetype');
  }
}

@mixin font-face-TTF($name, $file, $weight: noraml, $style: normal) {
  @font-face {
    font-family: '#{$name}';
    font-weight: $weight;
    font-style: $style;
    src: url('../assets/fonts/#{$file}.ttf') format('truetype');
  }
}

// IBMPlexSans
@include font-face-OTF("DINPro", "DINPro-Black", 900);
@include font-face-OTF("DINPro", "DINPro-Regular", normal);
@include font-face-OTF("DINPro", "DINPro-Medium", 500);
@include font-face-OTF("DINPro", "DINPro-Bold", bold);
@include font-face-OTF("Inter", "Inter Bold 700", bold);
@include font-face-OTF("Inter", "Inter Regular 400", normal);
@include font-face-TTF("Roboto", "Roboto-Black", 800);


body {
  margin: 0;
  font-family: 'DINPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.container{
  display: flex;
  justify-content: center;
  overflow: auto;
}

select{
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  width: 100%;
  height: 36px;
  padding: 5px 20px;
  border-radius: 4px;
  border: solid 2px #e7e9ef;
  background: url('../assets/images/arrow-down-filled.svg') no-repeat right 20px center;
  font-size: 14px;
  color: #3f4248;
  &:focus{
    outline: none;
    border: solid 2px #2657dc;
  }
  &.long{
    width: 550px;
  }
  &:disabled{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    padding: 0;
  }
}
html {
  scroll-behavior: smooth;
}

.ReactModal__Body--open {
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
}

.ReactModal__Content{
  outline: none;
}

.ReactModal__Overlay {
  position: fixed;
  outline: none;
  opacity: 0;
  transform: translateY(-50px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  outline: none;
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  outline: none;
  opacity: 0;
  transform: translateY(-50px);
}
.modal-overlay {
  position: fixed;
  width: calc(100% - var(--scroll-width, 15px));
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);

  @include media(767px) {
    width: 100%;
  }
}
