@import "src/view/style/mixins";
@import "src/view/style/variable";

#order-book {
  box-sizing: border-box;
  height: 100%;
  .order-book {
    &-title {
      box-sizing: border-box;
      padding: 16px 20px;
      height: 50px;
      letter-spacing: -0.34px;
      font-size: 14px;
      color: $exchange-text-main-color;
    }
    &-scroll {
      box-sizing: border-box;
      overflow-y: scroll;
      height: calc(100% - 93px);
      border-top: solid 1px $border-white-sub-color;
      border-bottom: solid 1px $border-white-sub-color;
    }
    &-table {
      height: 100%;
      tr {
        height: 40px;
      }
      td {
        font-size: 12px;
        letter-spacing: -0.29px;

        &.focused-box {
          background-color: $sub-background-color;
        }

        .inner-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          box-sizing: border-box;
          height: 95px;
          padding: 20px;
          .amount-label {
            word-break: break-word;
          }

          @include media(767px) {
            padding: 10px;
          }

          .label {
            font-size: 10px;
            letter-spacing: -0.24px;
            color: $text-sub-black-color;
            margin-bottom: 9px;
          }

          .symbol-label {
            color: $text-sub-black-color;
          }
        }

        &:first-child {
          vertical-align: top;
          .amount {
            &-td {
              justify-content: flex-end;
            }
            &-text {
              right: 10px;
            }
          }
          .inner-box {
            border-bottom: solid 1px $border-white-sub-color;
            &:last-child {
              border: 0;
            }
          }
        }

        &:last-child {
          vertical-align: bottom;
          text-align: left;
          .amount-text {
            left: 10px;
          }
          .inner-box {
            border-top: solid 1px $border-white-sub-color;
            text-align: right;
            &:first-child {
              border: 0;
            }
          }
        }
      }

      .amount {
        &-td {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          height: 100%;
          position: relative;
          &:hover {
            cursor: pointer;
          }
        }
        &-text {
          position: absolute;
          z-index: 1;
        }
        &-bar {
          height: 27px;
          position: absolute;
          z-index: 0;
          top: calc((100% - 27px) / 2);
        }
      }
      .sell-bar {
        right: 0;
        background-color: $sell-block-color;
      }

      .buy-bar {
        left: 0;
        background-color: $buy-block-color;
      }

      .price-box {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
        text-align: center !important;
        border-left: solid 1px $border-white-sub-color;
        border-right: solid 1px $border-white-sub-color;

        &:hover {
          cursor: pointer;
        }

        .value {
          font-size: 9px;
          letter-spacing: -0.24px;
          color: $text-sub-black-color;
        }

        .change {
          font-size: 11px;
          letter-spacing: -0.26px;
        }

        &.sell-box {
          background-color: $decrease-background-color;
          position: relative;

          &:hover::after {
            content: "";
            width: calc(175%);
            height: 1px;
            position: absolute;
            background-color: $decrease-color;
            top: 0;
            right: 0;
          }
        }

        &.buy-box {
          background-color: $increase-background-color;
          position: relative;

          &:hover::after {
            content: "";
            width: calc(175%);
            height: 1px;
            position: absolute;
            background-color: $increase-color;
            bottom: 0;
            left: 0;
          }
        }
      }

      .current-price {
        box-sizing: border-box;
        border: 1px solid $color-white !important;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          border-left: 6px solid $color-white;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }
  }
  .up, .buy {
    color: $increase-color;
  }
  .down, .sell {
    color: $decrease-color;
  }
  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    overflow: hidden;
    tr {
      border-top: solid 1px $border-white-sub-color;
      border-bottom: solid 1px $border-white-sub-color;
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    th, td {
      margin: 0;
      padding: 0;
      :last-child {
        border-right: none;
      }
    }
  }

  .total-amount-table {

    tr {
      height: 43px;
    }

    td {
      text-align: center;
      font-size: 12px;
      letter-spacing: -0.29px;
      font-weight: 500;
      border-right: solid 1px $border-white-sub-color;
      border-left: solid 1px $border-white-sub-color;

      &:first-child, &:last-child {
        border: none;
        font-weight: normal;
      }
    }
  }
}
