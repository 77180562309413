//width
$mobile_header_height: 50px;
$tablet_header_height: 60px;
$header_height: 80px;

//color
/* common color */
$common-yellow: #fabc3a !default;
$disable-color: #65697b !default;

/* main color */

/* font color */
$main-font-color: #dfe4ea !default;
$first-sub-font-color: #868ea3 !default;
$second-sub-font-color: #65697b !default;
$dark-blue-font-color: #1f1f2d !default;

/* Achromatic border, background color */
$common-background-color: white !default;
$dark-background-color: #1f1f2d !default;
$header-background-color: white !default;
$input-background-color: #f4f5f6 !default;
$dark-blue-border-color: #1f1f2d !default;
$dark-color: #171721 !default;
$sub-dark-color: #37374b !default;
$gray-border-color: #868ea3 !default;

/* extra color */
$gradient-color: linear-gradient(101deg, #fabc3a 3%, #f6953a 10%, #c82562 27%, #713c8b 51%, #24388b 79%, #329bd9 97%) !default;

/* box-shadow */

//font-size




//for exchange
/* common color */
$color-white: #fff;
$color-black: #000;

/* main color */
$increase-color: #46b98d;
$decrease-color: #de4014;
$section-main-color: #141313 !default;
$sub-background-color: #222;

$exchange-modal-background-color: #303133;

/* font color */
$exchange-text-main-color: #e5e5e5;
$text-sub-black-color: #afbbcf;
$text-main-gray-color: #afbbcf;

/* Achromatic border, background color */
$border-white-sub-color: #363636;
$border-gray-sub-color: #dbddeb;
$home-gray-background-color: #f5f5f5;
$increase-background-color: #f8fafe;
$decrease-background-color: #fef7f7;
$buy-block-color: #46b98d;
$sell-block-color: #df4014;

