@import "src/view/style/variable";
@import "src/view/style/mixins";

.modal-input{
  width: 100%;
  @include tablet{
    display: flex;
  }
  label{
    padding-top: 9px;
    min-width: 115px;
    font-size: 14px;
    color: #a3a3a3;
  }
  .sub-label{
    font-size: 12px;
  }
  input{
    width: 100%;
    padding: 9px 8px 9px 8px;
    border-radius: 14px;
    border: solid 1px #707070;
    background-color: #222222;
    font-size: 14px;
    font-weight: 500;
    color: white;
    box-sizing: border-box;
    word-break: break-all;
    font-family: 'DINPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    @include tablet{
      padding: 9px 16px 9px 16px;
    }
    &:focus{
      border-color: white;
      outline: white;
    }
    &:-webkit-autofill{
      background-color: inherit;
    }
    &:disabled{
      border: none;
      background-color: transparent; 
    }
  }
  textarea{
    width: 100%;
    height: 86px;
    padding: 9px 8px 9px 8px;
    border-radius: 14px;
    border: solid 1px #707070;
    background-color: #222222;
    font-size: 14px;
    font-weight: 500;
    word-break: break-all;
    color: white;
    box-sizing: border-box;
    @include tablet{
      padding: 9px 16px 9px 16px;
    }
    &:focus{
      border-color: white;
      outline: white;
    }
    &:-webkit-autofill{
      background-color: inherit;
    }
    &:disabled{
      border: none;
      background-color: transparent; 
    }
  }
}