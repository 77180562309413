table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0;
  box-sizing: border-box;
  overflow: hidden;
  border-top: solid 1px #c8c8c8;
  border-left: solid 1px #c8c8c8;
  font-size: 16px;
  font-weight: 500;
  color: #000;

  thead{
    border-bottom: solid 1px #c8c8c8;
  }

  th{
    border-bottom: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
    white-space: nowrap;
  }

  td{
    height: 100%;
    text-align: center;
    font-weight: normal;
    border-bottom: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
  }
  &:first-child {
    td {
      border-top: 1px solid #c8c8c8;
    }
  }
  &:last-child {
    td {
      border-bottom: none;
    }
  }

  tr{
    height: 36px;
    border-bottom: solid 1px #c8c8c8;
  }
}



