@import "src/view/style/mixins";
@import "src/view/style/variable";

.error-fallback {
  width: 100%;
  height: 100%;
  min-height: fit-content;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #707070;

  &-img {
    width: 357px;
    height: 438px;
    object-fit: contain;
  }

  h1 {
    margin: 50px 0 10px;
    font-size: 40px;
  }

  p {
    margin: 0 0 50px;
    line-height: 1.5;
    font-size: 18px;
    white-space: pre;
  }

  button {
    width: 200px;
    padding: 17px 0;
    text-align: center;
    border-radius: 29px;
    background-color: $increase-color;
    font-size: 18px;
    color: $color-white;
  }

  @include media(767px) {

    &-img {
      width: 80%;
      max-width: 300px;
      height: auto;
      object-fit: contain;
    }
    h1 {
      margin: 20px 0;
      font-size: 40px;
    }

    p {
      margin: 0 0 20px;
      line-height: 1.25;
      font-size: 18px;
      white-space: pre;
    }

    button {
      width: 200px;
      padding: 17px 0;
      text-align: center;
      border-radius: 29px;
      background-color: $increase-color;
      font-size: 18px;
      color: $color-white;
    }
  }

}
