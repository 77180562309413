@import "src/view/style/variable";
@import "src/view/style/mixins";

#public-sale {
  width: 100%;
  //padding: 0;
  position: relative;
  color: white;
  padding-top: calc(#{$mobile_header_height});
  @include tablet {
    padding-top: calc(#{$tablet_header_height});
  }
  @include laptop {
    padding-top: calc(#{$header_height});
  }
  @include desktop {
  }
  .public-sale {
    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 54px;
      background-color: #000;

      @include tablet {
        height: 80px;
      }
      @include laptop {
        height: 108px;
      }
      @include desktop {
        height: 137px;
      }

      &-text {
        &:first-child {
          font-family: "Inter";
          font-size: 12px;
          font-weight: 800;
          color: #ed1c24;

          @include tablet {
            font-size: 18px;
          }
          @include laptop {
            font-size: 22px;
          }
          @include desktop {
            font-size: 30px;
          }
        }

        &:nth-child(2) {
          margin-left: 10px;
          font-family: "Inter";
          color: #fff;
          font-size: 14px;
          font-weight: 600;

          @include tablet {
            margin-left: 14px;
            font-size: 24px;
          }
          @include laptop {
            margin-left: 18px;
            font-size: 36px;
          }
          @include desktop {
            margin-left: 30px;
            font-size: 41px;
          }
        }

        &:last-child {
          display: flex;
          align-items: flex-end;
          margin-left: 12px;
          background: linear-gradient(to right, #c22630, #f36002 105%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;

          @include tablet {
          }
          @include laptop {
            margin-left: 24px;
          }
          @include desktop {
            margin-left: 30px;
          }

          .amount {
            font-size: 16px;
            font-weight: bold;

            @include tablet {
              font-size: 22px;
            }
            @include laptop {
              font-size: 32px;
            }
            @include desktop {
              font-size: 44px;
            }
          }

          .unit {
            font-size: 13px;
            font-weight: normal;

            @include tablet {
              font-size: 18px;
            }
            @include laptop {
              font-size: 24px;
            }
            @include desktop {
              font-size: 33px;
            }
          }
        }
      }
    }
    &-background {
      background-color: #202124;
    }
    &-content {
      padding-top: 46px;
      padding-bottom: 100px;
      @include tablet {
        padding-top: 66px;
        padding-bottom: 140px;
      }
      @include laptop {
        padding-top: 78px;
        padding-bottom: 180px;
      }
      @include desktop {
        padding-top: 90px;
        padding-bottom: 200px;
      }
    }
    &-back-button {
      width: 32px;
      object-fit: contain;
    }
    &-layout {
      display: flex;
      flex-direction: column;
      padding: 40px 20px;
      border: 1px solid #ec5000;
      border-radius: 20px;
      @include desktop {
        justify-content: space-between;
        flex-direction: row;
      }
    }
    &-project {
      width: 100%;
      @include desktop {
        max-width: 550px;
      }
      &-text {
        font-size: 19px;
      }
      &-image {
        width: 50px !important;
        object-fit: contain;
        border-radius: 4px;
        @include tablet {
          width: 140px !important;
          object-fit: cover;
        }
      }
      &-name {
        font-size: 24px;
        font-weight: bold;
        color: #e5e5e5;
        word-break: break-all;
        @include tablet {
          font-size: 36px;
        }
      }
      &-net {
        font-size: 13px;
        font-weight: 500;
        color: #de4014;
      }
      &-content {
        font-size: 14px;
        line-height: 1.5;
        white-space: break-spaces;
        color: #e5e5e5;
      }
      &-homepage {
        font-size: 14px;
        color: #e5e5e5;
        text-decoration: underline;
      }
      &-links {
        display: flex;
      }
      &-link-icon {
        width: 34px;
        object-fit: contain;
      }
      &-button {
        font-size: 18px;
        font-weight: 500;
        padding: 12px 18px;
      }
    }

    &-state {
      width: 100%;
      margin: 36px 0 0 0;

      @include desktop {
        max-width: 610px;
        margin: 0;
        // margin: 0 0 0 105px;
      }

      &-view {
        padding: 15px;
        border-radius: 16px;
        background-color: #303133;
        @include tablet {
          padding: 30px;
        }
      }
      &-grid {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 5px));
        grid-row-gap: 10px;
        grid-column-gap: 10px;
      }
      &-remain-time {
        display: flex;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 14px;
        //background-image: linear-gradient(#303133, #303133), linear-gradient(to right, #c22630, #f36002);
        //background-origin: border-box;
        //background-clip: content-box, border-box;
        //&-content{
        //  box-sizing: border-box;
        //  padding: 14px;
        //}
      }
      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        //margin: 10px 0;
      }
      &-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        &-title {
          margin-bottom: 16px;
          font-size: 18px;
        }
        &-amount {
          background: linear-gradient(to right, #c22630, #f36002);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 24px;
          font-weight: bold;
          @include tablet {
            font-size: 36px;
          }
        }
        &-unit {
          font-size: 16px;
          font-weight: normal;
          color: #f36002;
          @include tablet {
            font-size: 20px;
          }
        }
      }
      &-sub {
        font-size: 16px;
        color: #a3a3a3;
        margin-top: 10px;

        @include tablet {
          font-size: 18px;
        }
      }
      &-progress {
        width: 100%;
        border-radius: 10px;
      }
      progress[max]::-webkit-progress-bar {
        width: 100%;
        height: 20px;
        padding: 3px;
        border-radius: 10px;
        background-color: #1f1c1c;
      }

      progress[value]::-webkit-progress-value {
        height: 14px;
        background-image: linear-gradient(to right, #df4914, #bf3027 51%, #791c5a);
        border-radius: 7px;
      }
      &-info {
        margin: 10px 0;
        font-size: 12px;
        color: #9a9a9a;
      }
    }
  }
  .main-helper {
    height: calc(500px + #{$header_height});
    background-color: #000;

    @media (min-width: 768px) {
      height: calc(870px + #{$header_height});
    }
    @media (min-width: 992px) {
      height: calc(1030px + #{$header_height});
    }
    @media (min-width: 1280px) {
      height: calc(1030px + #{$header_height});
    }
  }
}

.private-sale-text-normal {
  font-size: 16px;
  font-weight: 500;
  @include laptop {
    font-size: 24px;
  }
  @include desktop {
    font-size: 28px;
  }
}
.private-sale-text-small {
  font-size: 14px;
  font-weight: normal;
  @include laptop {
    font-size: 20px;
  }
  @include desktop {
    font-size: 24px;
  }
}
.private-sale-text-amount {
  font-size: 20px;
  font-weight: bold;
  @include laptop {
    font-size: 26px;
  }
  @include desktop {
    font-size: 36px;
  }
}

.button-container{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 20px;
  justify-items: center;
  @include tablet{
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 550px;
  margin: 0 auto;
  }
}