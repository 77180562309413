@import "src/view/style/mixins";
@import "src/view/style/variable";
#order-form {
  width: 100%;
  height: 100%;

  .buy {
    background-color: $increase-color !important;
  }
  .sell {
    background-color: $decrease-color !important;
  }

  .order-form {
    &-tab {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 52px;
      background-color: white;
      color: $text-sub-black-color;
      font-size: 14px;
      font-weight: 500;

      label {
        flex: 1;
        cursor: pointer;

        &:first-child {
          input[type="radio"]:checked + span {
            background-color: $increase-color;
          }
        }

        &:last-child {
          input[type="radio"]:checked + span {
            background-color: $decrease-color;
          }
        }
      }

      input[type="radio"] {
        display: none;
      }

      span {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: inline-block;
        background-color: #222222;
        color: $exchange-text-main-color;
        padding: 17px 0;
        text-align: center;
      }
    }

    &-content {
      &-wrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - 48px);
        padding: 25px 20px;
        font-size: 12px;
        color: $text-sub-black-color;
      }

      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > div:first-child {
          width: 30%;
          text-align: left;

          @include media(424px) {
            width: auto;
          }
        }

        > div:last-child {
          width: 70%;
          text-align: right;

          @include media(424px) {
            width: auto;
            text-align: left;
          }
        }

        .value {
          color: $exchange-text-main-color;
        }

        @include media(424px) {
          display: block;
        }
      }

      &-input {
        box-sizing: border-box;
        flex: 1;
        min-width: 0;
        padding: 9.6px 15px;
        font-size: 14px;
        letter-spacing: -0.34px;
        text-align: right;
        color: $exchange-text-main-color;
        background-color: $sub-background-color;
        border: none;
        outline-color: $decrease-color;

        @include media(767px) {
          padding: 4px 12px 5px;
        }

        &-text {
          font-size: 12px;
          color: $exchange-text-main-color;
          letter-spacing: -0.29px;
        }

        &-box {
          overflow: hidden;
          display: flex;
          border: solid 1px $border-white-sub-color;
          height: 40px;
          border-radius: 6px;

          @include media(767px) {
            height: 32px;
          }
        }

        &-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 42px;
          height: 100%;
          background-color: $sub-background-color;
          border-left: solid 1px $border-white-sub-color;

          @include media(767px) {
            width: 32px;
          }

          &-icon {
            width: 13px;
            height: auto;
            object-fit: contain;
          }

          &:hover {
            background: $section-main-color;
          }
        }
      }
    }

    &-rate-selector {
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      border: solid 1px $border-white-sub-color;
      background-color: $sub-background-color;
      width: 100%;
      height: 32px;
      overflow: hidden;

      &-item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $exchange-text-main-color;
        flex: 1;
        letter-spacing: -0.29px;

        &::before {
          box-sizing: border-box;
          content: "";
          position: absolute;
          left: 0;
          width: 1px;
          height: 13.1px;
          background-color: $border-white-sub-color;
        }

        &::after {
          box-sizing: border-box;
        }

        &:hover {
          background: $section-main-color;

          &::before {
            width: 0;
          }

          &::after {
            content: "";
            position: absolute;
            right: -1px;
            width: 1px;
            height: 13.1px;
            background-color: $sub-background-color;
            z-index: 1;
          }
        }

        &:first-child {
          &::before {
            width: 0;
          }
        }
      }
    }

    &-order-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      font-size: 12px;
      font-weight: 500;
      color: $color-white;

      &:disabled {
        background-color: $sub-background-color !important;
        cursor: default;
      }
    }
  }
}
