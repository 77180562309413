@import '../../style/variable';
@import 'src/view/style/mixins';

.modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e5e5e5;
}

.modal-border {
  @include border-radius(20px);
  outline: none;
  overflow: hidden;
  background-color: #303133;
  margin: 20px;
}
