@keyframes clippath {
  0%,
  100% {
    clip-path: inset(0 0 95% 0);
  }
  25% {
    clip-path: inset(0 95% 0 0);
  }
  50% {
    clip-path: inset(95% 0 0 0);
  }
  75% {
    clip-path: inset(0 0 0 95%);
  }
}

.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 0;
  width: 100%;
  background-color: #202124;

  @media (min-width: 768px) {
    padding: 60px 0 0;
  }
  @media (min-width: 992px) {
    padding: 80px 0 0;
  }
  @media (min-width: 1280px) {
  }
  .banner-section {
    width: 1920px;
    height: 1030px;
    @media (min-width: 768px) {
      height: 620px;
    }
    @media (min-width: 992px) {
      height: 760px;
    }
    @media (min-width: 1280px) {
      width: 2560px;
      height: 860px;
    }

    &-content {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      // overflow: hidden;

      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1280px) {
      }

      &-background {
        position: absolute;
        width: 1920px;
        height: 1030px;
        @media (min-width: 768px) {
          height: 620px;
        }
        @media (min-width: 992px) {
          height: 760px;
        }
        @media (min-width: 1280px) {
          width: 2560px;
          height: 860px;
        }

        &-src {
          height: 100%;
          width: 100%;
          // object-fit: contain;
          object-fit: cover;
        }
      }

      .banner-section-content-container {
        margin: 145px 0;
        z-index: 4;
        @media (min-width: 768px) {
          margin: 152px 0;
        }
        @media (min-width: 992px) {
          margin: 160px 0;
        }
        @media (min-width: 1280px) {
          margin: 210px 0;
        }
        .banner-section-content-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            width: 718px;
          }
          @media (min-width: 992px) {
            width: 932px;
          }
          @media (min-width: 1280px) {
            width: 1100px;
          }

          .banner-section-content-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            @media (min-width: 768px) {
            }
            @media (min-width: 992px) {
              cursor: pointer;
            }
            @media (min-width: 1280px) {
              height: 440px;
            }
          }
          .banner-section-content-left-img {
            width: 250px;
            object-fit: contain;
            @media (min-width: 768px) {
            }
            @media (min-width: 992px) {
              width: 320px;
            }
            @media (min-width: 1280px) {
              width: 346px;
            }
          }
          .banner-section-content-right-img {
            width: 250px;
            object-fit: contain;
            @media (min-width: 768px) {
            }
            @media (min-width: 992px) {
              width: 320px;
            }
            @media (min-width: 1280px) {
              width: 346px;
            }
          }
          .banner-section-content-line {
            width: 310px;
            // margin: 78px auto 70px auto;
            margin: 74px 0;
            border-top: 3px solid #fff;
            @media (min-width: 768px) {
              width: 1px;
              height: 180px;
              border-right: 3px solid #fff;
              // margin: 0 43px;
            }
            @media (min-width: 992px) {
              height: 200px;
            }
            @media (min-width: 1280px) {
              height: 237px;
            }
          }
          .banner-section-content-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 265px;
            margin-top: 30px;
            padding: 5px 0;
            border: 3px solid #fff;
            font-size: 18px;
            line-height: 24px;
            font-weight: 900;
            color: #fff;
            cursor: pointer;
            @media (min-width: 768px) {
              width: 320px;
              padding: 10px 0;
              font-size: 22px;
              line-height: 34px;
              font-weight: 900;
            }
            @media (min-width: 992px) {
              width: 370px;
              padding: 12px 0;
              font-size: 26px;
              line-height: 36px;
              font-weight: 900;
            }
            @media (min-width: 1280px) {
              display: flex;
              justify-content: center;
              width: 470px;
              padding: 17px 0;
              border: 3px solid #fff;
              font-size: 32px;
              line-height: 41px;
              font-weight: 900;
              color: #fff;
            }
          }
          .banner-section-content-btn-img {
            margin: auto 0 auto 7px;
            width: 20px;
            height: 20px;
            @media (min-width: 768px) {
              margin: auto 0 auto 10px;
            }
            @media (min-width: 992px) {
              width: 25px;
              height: 25px;
              margin: auto 0 auto 12px;
            }
            @media (min-width: 1280px) {
              width: 30px;
              height: 30px;
              margin: auto 0 auto 15px;
            }
          }
          .banner-section-content-btn:hover {
            background-color: #fff;
            color: #211014;
          }
        }
      }
    }
  }
}

.banner-section-content-text {
  width: 300px;
  margin: 26px auto 0 auto;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  font-size: 26px;
  line-height: 38px;
  font-weight: 900;
  text-align: center;
  @media (min-width: 768px) {
    width: 500px;
    margin-top: 50px;
    padding: 5px 0;
    line-height: 50px;
    font-size: 34px;
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1280px) {
    width: 600px;
    margin: 52px auto 0 auto;
    font-size: 50px;
    line-height: 64px;
    font-weight: 900;
  }
}
