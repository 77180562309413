.loading{
  width: 43.8px;
  object-fit: contain;
  -webkit-animation:spin 1.4s ease infinite;
  -moz-animation:spin 1.4s ease infinite;
  animation:spin 1.4s ease infinite;
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}
