.inline-content-wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
}

.inline-content{
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  max-width: 360px;

  @media (min-width: 768px) {
    text-align: left;
    max-width: 768px;
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
  @media (min-width: 1280px) {
    max-width: 1280px;
  }
}