@import "src/view/style/variable";
@import "src/view/style/mixins";

.modal-input-with-unit{
  position: relative;
  width: 100%;
  @include tablet{
    display: flex;
  }
  &-unit{
    position: absolute;
    bottom: 10px;
    right: 8px;
    font-size: 14px;
    background-color: #303133;
    @include tablet{
      bottom: 10px;
      right: 16px;
    }
  }
}