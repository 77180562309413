.home-section2 {
  padding: 60px 0;
  color: white;

  @media (min-width: 768px) {
    padding: 80px 0;
  }
  @media (min-width: 992px) {
    padding: 100px 0;
  }
  @media (min-width: 1280px) {
    padding: 112px 0;
  }
}

.home-section2-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  width: 100%;
  max-width: 2560px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1280px) {
    justify-content: center;
    align-items: center;
  }

  .section2-wrapper {
    position: relative;
    height: 100%;
  }
}

.section2-text-wrapper {
  margin-left: 0;

  @media (min-width: 768px) {
    margin-left: 20px;
  }
  @media (min-width: 992px) {
    margin-left: 40px;
  }
  @media (min-width: 1280px) {
    margin-left: 63px;
  }

  .section2-title {
    font-family: "Roboto";
    margin-bottom: 20px;
    font-size: 31px;
    font-weight: 800;
    text-align: center;
    color: #d94525;
    white-space: break-spaces;

    @media (min-width: 768px) {
      margin-bottom: 20px;
      font-size: 44px;
      text-align: left;
    }
    @media (min-width: 992px) {
      margin-bottom: 20px;
      font-size: 52px;
    }
    @media (min-width: 1280px) {
      margin-bottom: 40px;
      font-size: 62px;
    }
  }

  .section2-sub-title {
    margin-bottom: 50px;
    max-width: 444px;
    font-size: 14px;
    line-height: 1.38;
    color: #fff;
    white-space: break-spaces;

    @media (min-width: 768px) {
      margin-bottom: 0;
      max-width: 444px;
      text-align: left;
    }
    @media (min-width: 992px) {
      font-size: 16px;
    }
    @media (min-width: 1280px) {
    }
  }
}

.phone-img-box {
  position: relative;
  min-width: 310px;
  max-width: 310px;
  height: 310px;
  background: radial-gradient(circle at 50% 50%, #b20, rgba(187, 34, 0, 0) 71%);

  @media (min-width: 768px) {
    min-width: 328px;
    max-width: 328px;
    height: 328px;
  }
  @media (min-width: 992px) {
    min-width: 448px;
    max-width: 448px;
    height: 448px;
  }
  @media (min-width: 1280px) {
    min-width: 620px;
    max-width: 620px;
    height: 620px;
  }

  .home-section2-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 240px;
    object-fit: contain;

    @media (min-width: 768px) {
      width: 240px;
    }
    @media (min-width: 992px) {
      width: 370px;
    }
    @media (min-width: 1280px) {
      width: 482px;
    }
  }
}

.section2-background-img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 2560px;
  height: 860px;
  z-index: -1;
}
