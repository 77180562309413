@import "src/view/style/mixins";
@import "src/view/style/variable";

.checking-modal {
  display: flex;
  flex-direction: column;
  max-width: 372px;
  max-height: min(80vh, 550);
  color: $exchange-text-main-color;
  border-radius: 20px;
  background-color: $section-main-color;
  border: solid 1px $border-white-sub-color;
  outline: none;
  font-size: 14px;

  .modal-header {
    min-height: 60px;
  }

  .modal-content {
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      &-thumb {
        background-color: $sub-background-color;
        border-radius: 3px;
      }
    }

    .modal-content-scroll-area {
      height: 490px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .close-btn-img {
    width: 26px;
    height: 26px;
    object-fit: contain;
  }

  .notice {
    font-size: 12px;
    text-align: center;
  }

  .token-img {
    width: 14px;
    height: 14px;
    object-fit: contain;
  }

  #select-checking-tab {
    width: 100%;
    display: flex;
    justify-content: center;

    label {
      flex: 1;
      max-width: 165px;
      cursor: pointer;
      &:last-child {
        span {
          border-left: none;
        }
      }
    }
    input[type="radio"] {
      display: none;
    }
    span {
      width: 100%;
      display: inline-block;
      padding: 6px 0;
      text-align: center;
      background-color: $sub-background-color;
    }
    input[type="radio"]:checked + span {
      background-color: $increase-color;
      font-weight: 500;
    }
  }

  .checking-type-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 30px;
    border: solid 1px #e3e4e9;

    &:last-child {
      border-left: none;
    }
  }

  .currency-input {
    flex: 1;
    min-width: 50px;
    border: solid 1px $border-white-sub-color;
    border-radius: 5px;
    outline: none;
    background-color: $sub-background-color;
    padding: 6px 10px;
    text-align: right;
    font-weight: 500;
    color: $exchange-text-main-color;
    &:focus {
      border-color: $decrease-color;
    }
  }

  .symbol {
    margin-left: 5px;
    font-size: 10px;
    color: $decrease-color;
  }

  table {
    table-layout: fixed;
    width: 100%;
    font-size: 14px;

    thead {
      td {
        color: $increase-color;
        font-weight: bold;
        text-align: left !important;
      }
    }

    tr {
      td {
        padding: 5px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .checking-button {
    height: 50px;
    border-radius: 5px;
    background-color: $increase-color;
    font-size: 14px;
    font-weight: 500;
    color: #fff;

    &:disabled {
      background-color: $sub-background-color;
      cursor: default;
    }
  }
}
