.home-section1 {
  // height: 840px;
  height: 550px;
  color: white;
  position: absolute;
  // TODO :: section1 위치
  top: 1080px;


  @media (min-width: 768px) {
    top: 700px;
    height: 920px;
  }
  @media (min-width: 992px) {
    top: 840px;
    height: 1080px;
  }
  @media (min-width: 1280px) {
    top: 940px;
    height: 1080px;
  }

  .volrscan-button-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 92px;

    @media (min-width: 768px) {
      flex-direction: row;
      margin-top: 72px;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1280px) {
    }

    .volrscan-button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      border-radius: 9px;
      border: 1px solid #fff;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      @media (min-width: 768px) {
        width: 160px;
        height: 57px;
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1280px) {
      }

      .join-img {
        margin-right: 4px;
        fill: #fff;
        width: 16px;
        object-fit: contain;
      }

      &:first-child {
        margin: 0 0 16px 0;

        @media (min-width: 768px) {
          margin: 0 27px 0 0;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
        }
      }
    }
  }
}

.home-section1-content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .main-title-wrapper {
    .main-title {
      font-family: "Inter";
      // font-size: 26px;
      font-size: 32px;
      font-weight: bold;
      text-align: left;
      line-height: 1.13;
      color: #fff;
      white-space: break-spaces;

      @media (min-width: 768px) {
        font-size: 44px;
      }
      @media (min-width: 992px) {
        text-align: left;
        font-size: 68px;
      }
      @media (min-width: 1280px) {
        font-size: 88px;
        line-height: 1.14;
      }
    }
    .sub-title {
      margin: 0;
      // font-size: 12px;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #fff;
      white-space: break-spaces;

      @media (min-width: 768px) {
        margin: 0 auto;
        font-size: 22px;
      }
      @media (min-width: 992px) {
        margin: 0;
        text-align: left;
        font-size: 30px;
      }
      @media (min-width: 1280px) {
        font-size: 36px;
      }

      &.top {
        margin-bottom: 20px;

        @media (min-width: 768px) {
          margin-bottom: 30px;
        }
        @media (min-width: 992px) {
          margin-bottom: 60px;
        }
        @media (min-width: 1280px) {
          margin-bottom: 60px;
        }
      }

      &.bottom {
        margin-top: 20px;

        @media (min-width: 768px) {
          margin-top: 30px;
        }
        @media (min-width: 992px) {
          margin-top: 60px;
        }
        @media (min-width: 1280px) {
          margin-top: 60px;
        }
      }
    }
  }
}
