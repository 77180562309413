@import "src/view/style/variable";

#Home {
  width: 100%;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  .main-helper {
    height: 550px;
    background-color: #000;

    @media (min-width: 768px) {
      height: 920px;
    }
    @media (min-width: 992px) {
      height: 1080px;
    }
    @media (min-width: 1280px) {
      height: 1080px;
    }
  }

  .video-box {
    position: absolute;
    // TODO :: section1 위치
    width: 2560px;
    // height: 840px;
    height: 550px;
    top: 1080px;

    @media (min-width: 768px) {
      top: 680px;
      height: 920px;
    }
    @media (min-width: 992px) {
      top: 840px;
      height: 1080px;
    }
    @media (min-width: 1280px) {
      top: 940px;
      height: 1080px;
    }
    .main-video {
      width: 100%;
      object-fit: cover;
      // height: 840px;
      height: 550px;

      @media (min-width: 768px) {
        height: 920px;
      }
      @media (min-width: 992px) {
        height: 1080px;
      }
      @media (min-width: 1280px) {
        height: 1080px;
      }
    }
  }
}
