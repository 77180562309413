@import "src/view/style/mixins";

.round-key-value-box{
  padding: 14px 20px;
  border-radius: 14px;
  border: solid 1px #707070;
  &-label{
    font-size: 12px;
    text-align: center;
    color: #a3a3a3;
    @include tablet {
      font-size: 16px;
    }
  }
  &-text{
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    word-break: break-all;
    @include tablet {
      font-size: 16px;
    }
  }
}
