.input-tag{
  display: flex;
  width: 100%;
  label{
    padding-top: 9px;
    min-width: 159px;
    font-size: 14px;
    color: #a3a3a3;
  }
  .sub-label{
    font-size: 12px;
  }
  input{
    width: 100%;
    padding: 9px 60px 9px 16px;
    border-radius: 14px;
    //border: solid 1px #707070;
    border: none;
    outline: none;
    background-color: #202020;
    font-size: 14px;
    color: white;
    font-family: 'DINPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    &:focus{
      border-color: white;
      outline: white;
    }
    &:-webkit-autofill{
      background-color: inherit;
    }
  }
  textarea{
    width: 100%;
    height: 86px;
    padding: 9px 60px 9px 16px;
    border-radius: 14px;
    border: solid 1px #707070;
    background-color: #202020;
    font-size: 14px;
    color: white;
    box-sizing: border-box;
    &:focus{
      border-color: white;
      outline: white;
    }
    &:-webkit-autofill{
      background-color: inherit;
    }
  }
}