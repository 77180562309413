.round-state-box{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: #303133;
  font-size: 14px;
  font-weight: 500;
  color: #e5e5e5;
}
