
button{
  padding :0;
  border:none;
  background : transparent;
  outline: none;
  cursor: pointer;
  &:disabled{
    cursor: inherit;
  }
}

p{
  margin: 0;
}

a{
  text-decoration: none;
  display: flex;
}

textarea{
  font-family: 'DINPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  outline: none; resize: none;
  background: inherit; border:none;
}