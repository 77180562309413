#JoinAirdropModal {   
  position: relative;
  width: 312px;
  height: 500px;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #202124;

  @media (min-width: 768px) {
    width: 680px;
    height: 600px;
    padding: 40px;
  }
  @media (min-width: 992px) {
    width: 760px;
    height: 700px;
    padding: 40px;
  }
  @media (min-width: 1280px) {
    width: 880px;
    height: 800px;
  }

  .join-airdrop-modal-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #fff;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .top-wrapper {
      display: flex;
      flex-direction: column;
      background-color: #202124;
      z-index: 10;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 40px;
        padding-bottom: 20px;
        width: calc(100% - 80px);
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1280px) {
      }

      .top-left-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        @media (min-width: 768px) {
          width: fit-content;
        }
        @media (min-width: 992px) {

        }
        @media (min-width: 1280px) {

        }

        .img-wrapper {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          margin-right: 20px;
          padding: 4px;
          width: 80px;
          height: 80px;
          border: 1px solid #3d3f43;
          border-radius: 6px;

          @media (min-width: 768px) {
            padding: 6px;
            width: 92px;
            height: 92px;
            border-radius: 8px;
          }
          @media (min-width: 992px) {
            padding: 8px;
            width: 100px;
            height: 100px;
            border-radius: 10px;
          }
          @media (min-width: 1280px) {
            padding: 8px;
            width: 108px;
            height: 108px;
            border-radius: 12px;
          }

          .card-img {
            width: 100%;
            border-radius: 100%;
            object-fit: cover;
            @media (min-width: 768px) {
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
            }
          }
        }

        .data-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .name-wrapper {
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: normal;
            @media (min-width: 768px) {
              margin-bottom: 16px;
              font-size: 20px;
            }
            @media (min-width: 992px) {
              margin-bottom: 20px;
              font-size: 24px;
            }
            @media (min-width: 1280px) {
              margin-bottom: 20px;
              font-size: 24px;
            }
          }

          .network-chain-wrapper {
            font-size: 12px;
            font-weight: normal;
            color: #297cfd;
            @media (min-width: 768px) {
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
              font-size: 16px;
              font-weight: bold;
            }

            .data-text {
              padding: 0 4px;
              color: #297cfd;
            }
          }

          .sns-wrapper {
            display: flex;
            @media (min-width: 768px) {
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
            }

            .sns-img {
              width: 16px;
              object-fit: contain;
              margin-right: 14px;
              cursor: pointer;

              @media (min-width: 768px) {
                margin-right: 16px;
                width: 18px;
              }
              @media (min-width: 992px) {
                margin-right: 18px;
                width: 20px;
              }
              @media (min-width: 1280px) {
                margin-right: 20px;
                width: 24px;
              }

              &.last {
                margin-right: 0;
              }
            }
          }
        }
      }

      .top-right-wrapper {
        margin: 20px 0 0 0;
        @media (min-width: 768px) {
          margin: 0;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
        }
        .time-remaining-wrapper {
          .time-remaining-title {
            margin-bottom: 4px;
            font-size: 14px;
            font-weight: 500;
            color: #e5d2d2;
          }

          .time-remaining-row-wrapper {
            display: flex;
            align-items: center;
            margin: 12px 0 0 0;
            @media (min-width: 768px) {
              margin: 0;
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
            }

            .time-remaining {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 26px;

              .time-data {
                margin-bottom: 4px;
                font-size: 24px;
                color: #fc7428;
                width: 100%;
                text-align: center;
              }

              .time-data-unit {
                font-size: 12px;
              }

              &.day {
                margin: 0 32px 0 0;
                @media (min-width: 768px) {
                  margin: 0 40px 0 0;
                }
                @media (min-width: 992px) {
                }
                @media (min-width: 1280px) {
                }
              }
            }

            .dot {
              margin-bottom: auto;
              padding: 0 14px;
              font-size: 22px;
              @media (min-width: 768px) {
                padding: 0 16px;
              }
              @media (min-width: 992px) {
                padding: 0 18px;
              }
              @media (min-width: 1280px) {
                padding: 0 20px;
              }
            }
          }
        }
      }
    }

    .type-wrapper {
      margin-top: 40px;
      @media (min-width: 768px) {
        margin-top: calc(50px + 92px);
      }
      @media (min-width: 992px) {
        margin-top: calc(50px + 100px);
      }
      @media (min-width: 1280px) {
        margin-top: calc(50px + 108px);
      }

      .text-wrapper {
        margin-bottom: 26px;
        font-size: 14px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
        @media (min-width: 992px) {
          font-size: 16px;
        }
        @media (min-width: 1280px) {
          font-size: 18px;
        }
      }

      .requirement-wrapper {
        .requirement-row {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #3d3f43;

          @media (min-width: 768px) {
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1280px) {
          margin-bottom: 5px;
          padding-bottom: 5px;
          }

          &:last-child { 
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
          }
          @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1280px) {
          }

          .title {
            width: 100%;
            font-size: 14px;
            text-align: left;
            @media (min-width: 768px) {
              width: inherit;
              font-size: 16px;
            }
            @media (min-width: 992px) {
              font-size: 16px;
            }
            @media (min-width: 1280px) {
              font-size: 18px;
            }
          }

          .link-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 0 0 0;
            width: 198px;
            height: 48px;
            color: white;
            background-color: #d94525;
            border-radius: 10px;
            text-decoration: none;
            cursor: pointer;
            @media (min-width: 768px) {
              margin: 0;
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
              width: 198px;
              height: 48px;
            }
          }
        }
      }
      .text-box-wrapper{
        .text-box-title{
          padding-left: 10px;
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: bold;
          @media (min-width: 768px) {
            font-size: 20px;
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1280px) {
          }
        }
        .content-input{
          width: calc(100%);
          height: 310px;
          padding: 20px;
          box-sizing: border-box;
          font-family: inherit;
        }
        textarea{
          resize: none;
          font-size: 14px;
          &:focus{
            outline: none;
          }
          @media (min-width: 768px) {
            font-size: 18px;
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1280px) {
          }
        }
        textarea::placeholder{
          font-family: DINPro;
          font-size: 14px;
          color: rgba(63, 66, 72, 0.3);
          @media (min-width: 768px) {
            font-size: 18px;
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1280px) {
          }
        }
      }
      .input-box-wrapper {
        margin: 80px 0 72px 0;
        @media (min-width: 768px) {
          margin: 35px 0 80px 0;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
          margin: 35px 0 100px 0;
        }
        .input-wrapper {
          margin: 0 0 24px 0;

          @media (min-width: 768px) {
          margin: 10px 0 20px 0;
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1280px) {
          }

          .input-title {
            margin-bottom: 10px;
            font-size: 14px;
            @media (min-width: 768px) {
              padding-left: 10px;
              font-size: 20px;
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
              padding-left: 10px;
              font-size: 20px;
            }
          }

          .input-box {
            width: calc(100%);
            height: 50px;
            font-size: 14px;
            padding: 0 60px 0 20px;
            border-radius: 5px;
            border: 1px solid rgba(112, 112, 112, 0.5);
            background-color: #202124;
            color: #fff;
            box-sizing: border-box;
            @media (min-width: 768px) {
              font-size: 18px;
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
            }

          }

          input:focus {
            outline: none;
          }

          input::placeholder {
            font-size: 14px;
            color: #707070;
            @media (min-width: 768px) {
              font-size: 18px;
            }
            @media (min-width: 992px) {
            }
            @media (min-width: 1280px) {
            }
          }

          .length-size {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            color: #707070;
            font-size: 12px;

            .length {
              color: #707070;
            }
          }
        }
      }

    }
    .toggle-wrapper{
      .toggle-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 50px;
        @media (min-width: 768px) {
          margin-bottom: 40px;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
        }
        .text-wrapper{
          font-size: 14px;
          width: 230px;
          @media (min-width: 768px) {
            font-size: 16px;
            width: 540px;
          }
          @media (min-width: 992px) {
            font-size: 16px;
            width: 630px;
          }
          @media (min-width: 1280px) {
            font-size: 18px;
            width: 740px;
          }
        }

        .terms-check-box {
          width: 20px;
          height: 20px;
          outline: none;
          border: none;
          background-image: url("../../assets/images/check-square.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          background-color: #fff;
          border-radius: 4px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          @media (min-width: 768px) {
            width: 24px;
            height: 24px;
          }
          @media (min-width: 992px) {
            width: 24px;
            height: 24px;
          }
          @media (min-width: 1280px) {
            width: 24px;
            height: 24px;
          }
          
          &:checked {
            background-image: url("../../assets/images/check-square-fill.png");
          }
        }

        .react-toggle,
        .custom-toggle.react-toggle--checked {
          border: 1px solid #d94525;
          border-radius: 30px;
        }
        .custom-toggle {
          border: 1px solid #876159;

        }
        .react-toggle-track {
          background-color: #202124;
        }
        .custom-toggle.react-toggle--checked .react-toggle-track {
          background-color: #202124;
        }
        .react-toggle-thumb {
          background-color: #876159;
        }
        .custom-toggle.react-toggle--checked .react-toggle-thumb {
          background-color: #d94525;
          border: none;
          outline: none;
        }
        .react-toggle--focus .react-toggle-thumb {
          box-shadow: none !important;
        }



        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .btn-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px 0 0 0;
      padding: 0 0 20px 0;
      @media (min-width: 768px) {
        margin: 100px 0 0 0;
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1280px) {
      }
      .confirm-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
        height: 50px;
        border-radius: 10px;
        background-color: #d94525;
        border: none;
        color:white;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        @media (min-width: 768px) {
          width: 100%;
          height: 48px;
          font-size: 18px;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
        }

        &:disabled {
          background-color: #876159;
          cursor: default;
        }
        @media (min-width: 768px) {
          width: 200px;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
        }
      }
    }
  }
}
