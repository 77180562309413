.home-section3 {
  position: relative;
  // background-color: #202124;
  color: white;
  background-color: transparent;

  h1 {
    margin: 0;
    padding: 176px 0 0;
    font-size: 72px;
    font-weight: 900;
    letter-spacing: -1.44px;
    text-align: center;
    color: #fff;
    white-space: break-spaces;
  }
  h2 {
    // margin: 130px 0 0;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -0.96px;
    color: #ee7029;
    white-space: break-spaces;
  }
  h3 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.72px;
    color: #fff;
    white-space: break-spaces;
    height: 92px;
  }
  p {
    font-size: 16px;
    line-height: 1.38;
    color: #fff;
    white-space: break-spaces;
  }

  &-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  &-roadmap {
    width: 300%;
    margin: 60px 30px 0;
    @media (min-width: 768px) {
      width: 100%;
      margin: 100px 0 0;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1280px) {
      margin: 231px 0 0;
    }
  }

  &-ecosystem-img {
    width: 170%;
    left: 0;
    top: 0;
    margin-top: 38px;
    @media (min-width: 768px) {
      width: 100%;
      margin-top: 56px;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1280px) {
    }
  }

  &-block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0;

    @media (min-width: 768px) {
      flex-direction: row;
      height: 520px;
    }
    @media (min-width: 992px) {
      height: 640px;
    }
    @media (min-width: 1280px) {
      padding: 40px 0;
      justify-content: space-between;
      align-items: center;
      height: 800px;
    }

    &.reverse {
      flex-direction: column-reverse;

      @media (min-width: 768px) {
        flex-direction: row;
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1280px) {
      }
    }

    .section3-text-box {
      padding: 0 15px;
      max-width: 310px;

      @media (min-width: 768px) {
        padding: 0;
        max-width: 360px;
      }
      @media (min-width: 992px) {
        max-width: 400px;
      }
      @media (min-width: 1280px) {
        max-width: 580px;
      }

      .section3-content-title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.96px;
        color: #ee7029;
        white-space: break-spaces;

        @media (min-width: 768px) {
          flex-direction: row;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
          font-size: 48px;
        }
      }

      .section3-content-sub-title {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.96px;
        color: #ee7029;
        white-space: break-spaces;

        @media (min-width: 768px) {
          flex-direction: row;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
          font-size: 28px;
        }
      }

      .section3-content-description {
        font-size: 16px;
        line-height: 1.13;
        color: #fff;
        white-space: break-spaces;

        @media (min-width: 768px) {
          margin-bottom: 0;
          line-height: 1.38;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
          font-size: 18px;
        }
      }

      .see-more {
        margin-bottom: 100px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        color: #ee7029;
        text-decoration: underline;
        @media (min-width: 768px) {
          margin-bottom: 0;
          font-size: 18px;
          line-height: 1.44;
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1280px) {
        }
        &-arrow {
          width: 8px;
          height: 16px;
          margin: 0 0 0 5px;
          object-fit: contain;
        }
      }
    }

    .section3-img {
      &.technology-img-1 {
        width: 310px;
        object-fit: contain;

        @media (min-width: 768px) {
          width: 336px;
        }
        @media (min-width: 992px) {
          width: 506px;
        }
        @media (min-width: 1280px) {
          width: 626px;
        }
      }

      &.technology-img-2 {
        width: 310px;
        object-fit: contain;

        @media (min-width: 768px) {
          width: 340px;
        }
        @media (min-width: 992px) {
          width: 540px;
        }
        @media (min-width: 1280px) {
          width: 700px;
        }
      }

      &.ecosystem-img-1 {
        width: 310px;
        object-fit: contain;

        @media (min-width: 768px) {
          width: 340px;
        }
        @media (min-width: 992px) {
          width: 460px;
        }
        @media (min-width: 1280px) {
          width: 541px;
        }
      }

      &.ecosystem-img-2 {
        width: 310px;
        object-fit: contain;

        @media (min-width: 768px) {
          width: 340px;
        }
        @media (min-width: 992px) {
          width: 480px;
        }
        @media (min-width: 1280px) {
          width: 591px;
        }
      }

      &.ecosystem-img-3 {
        width: 310px;
        object-fit: contain;

        @media (min-width: 768px) {
          width: 340;
        }
        @media (min-width: 992px) {
          width: 470px;
        }
        @media (min-width: 1280px) {
          width: 593px;
        }
      }
    }
  }

  &-divide-line {
    margin: 24px 0;
    width: 70px;
    border-bottom: 1px solid #fff;

    @media (min-width: 768px) {
      margin: 30px 0;
      width: 100px;
    }
    @media (min-width: 992px) {
      margin: 38px 0;
      width: 120px;
    }
    @media (min-width: 1280px) {
      margin: 48px 0;
      width: 140px;
    }
  }

  .home-section3-content {
    .section3-title-wrapper {
      padding: 96px 0 27px;
      text-align: center;
      background-color: transparent;

      @media (min-width: 768px) {
        padding: 102px 0 42px;
      }
      @media (min-width: 992px) {
        padding: 142px 0 60px;
      }
      @media (min-width: 1280px) {
        padding: 214px 0 72px;
      }

      .section3-title {
        margin-bottom: 10px;
        font-size: 36px;
        font-weight: 900;
        letter-spacing: -1.44px;
        white-space: break-spaces;

        @media (min-width: 768px) {
          margin-bottom: 10px;
          font-size: 48px;
        }
        @media (min-width: 992px) {
          margin-bottom: 16px;
          font-size: 60px;
        }
        @media (min-width: 1280px) {
          margin-bottom: 20px;
          font-size: 72px;
        }
      }
      .section3-sub-title {
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
        max-width: 310px;

        @media (min-width: 768px) {
          max-width: none;
          font-size: 20px;
        }
        @media (min-width: 992px) {
          font-size: 26px;
        }
        @media (min-width: 1280px) {
          font-size: 32px;
          letter-spacing: -0.2px;
        }
      }
      &.gradient {
        // background-image: linear-gradient(to bottom, #060204 0%, #202124 100%);
        // margin: 0 calc(165px - 50vw);
        // padding: 96px calc(50vw - 165px) 27px;
        // width: 2560px;
        // max-width: 2560px;

        @media (min-width: 768px) {
          // margin: 0 calc(369px - 50vw);
          // padding: 96px calc(50vw - 369px) 27px;
        }
        @media (min-width: 992px) {
          // margin: 0 calc(481px - 50vw);
          // padding: 96px calc(50vw - 481px) 27px;
        }
        @media (min-width: 1280px) {
          // margin: 0 calc(625px - 50vw);
          // padding: 96px calc(50vw - 625px) 27px;
        }
      }
    }
  }
}

// .image-box {
//   position: relative;
//   width: 592px;

//   .ecosystem-img-1{
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 494px;
//     object-fit: contain;
//     z-index: 1;
//   }

//   .ecosystem-img-2{
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 592px;
//     object-fit: contain;
//     z-index: 1;
//   }
// }

// .ecosystem-img-3{
//   width: 624px;
//   object-fit: contain;
// }

.slide-img-view {
  position: relative;
  width: 100%;
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1280px) {
  }
  .right-blur {
    position: absolute;
    right: 0px;
    bottom: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(to left, #202124, rgba(32, 33, 36, 0));
    z-index: 1;
    @media (min-width: 768px) {
      display: none;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1280px) {
    }
  }
  .left-blur {
    position: absolute;
    left: 0px;
    bottom: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(to right, #202124, rgba(32, 33, 36, 0));
    z-index: 1;
    @media (min-width: 768px) {
      display: none;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1280px) {
    }
  }
  .slide-img-view-slide {
    position: relative;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.section3-gradient {
  position: absolute;
  top: 0;
  background-image: linear-gradient(to bottom, #060204 0%, #202124 100%);
  width: 2560px;
  // height: 100px;
  height: 200px;
  @media (min-width: 768px) {
    height: 420px;
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1280px) {
  }
}
